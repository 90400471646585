import {useEffect, useRef, useState} from "react";


const userID = 1;

function handleError(msg, error) {
	console.error(msg, error);
}

function toLocalDateTimeObject(date) {
	const localDate = new Date(date);
	const year = localDate.getFullYear();
	const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
	const day = localDate.getDate().toString().padStart(2, '0');
	const hours = localDate.getHours().toString().padStart(2, '0');
	const minutes = localDate.getMinutes().toString().padStart(2, '0');
	const seconds = localDate.getSeconds().toString().padStart(2, '0');

	return {
		date: `${year}-${month}-${day}`,
		time: `${hours}:${minutes}:${seconds}`
	};
}


export default function VMR() {
	const [mode, setMode] = useState("startup");
	const [elapsed, setElapsed] = useState(0);
	// const [promptIdx, setPromptIdx] = useState(0);
	const [promptTxt, setPromptTxt] = useState("<nothing scheduled yet>");
	const [promptID, setPromptID] = useState(0);

	const modeRef = useRef(mode);
	const oldMode = useRef("_none_");

	/** @type {React.MutableRefObject<MediaRecorder|null>} */
	const mediaRecorder = useRef();
	const audioChunks = useRef([]);

	// Time zone in IANA format, e.g. 'Europe/Berlin'
	const timeZoneIANA = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone);

	// Local time zone, e.g., CST.	This is not the same as the IANA time zone.
	const timeZoneLocal = useRef(new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]);

	/** @type {React.MutableRefObject<Blob|null>} */
	const audioBlob = useRef();
	const durationRef = useRef(0);
	const interval = useRef(null);

	useEffect(() => {
		// This is the main state machine.  It's triggered by changes to the mode, and it handles the mode transitions.
		// Other dependencies are used to trigger side effects, like the elapsed time, or the promptTxt index, but they
		// don't trigger state transitions, and they don't need to be handled here.
		if (mode === oldMode.current) {
			// console.log("useEffect: mode is: " + mode + " and oldMode is: " + oldMode.current + " so returning.");
			return;
		}
		console.log("useEffect: mode is: " + mode + " and oldMode is: " + oldMode.current);

		// Some timeout handlers floating around will need to know what the current mode is.
		modeRef.current = mode;

		function startTimer() {
			// console.log("setting elapsed to zero.")
			setElapsed(0);
			resumeTimer()
		}
		function handleStartRecording() {
			const createMediaRecorder = async () => {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
					const mediaOptions = {
						// NOTE: Safari somehow doesn't have this one??
						// And audio/mp4 container (which Safari defaults to) seemingly can't be decoded by OpenAI, even
						// using the proper audio/mp4 mimetype.  So dunno.
						//mimeType: 'audio/webm;codecs=opus'
					}

					mediaRecorder.current = new MediaRecorder(stream, mediaOptions);
					console.log("[early] mimeType ==> " + mediaRecorder.current.mimeType);

					mediaRecorder.current.ondataavailable = (event) => {
						if (event.data.size > 0) {
							audioChunks.current.push(event.data);
						}
					};

					mediaRecorder.current.onstart = () => {
						// Note: if you don't ask for a specific codec, the one you get remains un-specified until the onstart event.
						console.log("[late] mimeType ==> " + mediaRecorder.current.mimeType);
						startTimer();
					};

					mediaRecorder.current.onstop = async () => {
						clearTimer();
						audioBlob.current = new Blob(audioChunks.current, { type: mediaRecorder.current.mimeType});
						// console.log("Doing transcription in python now.")

						await postResponseData(promptID, promptTxt, elapsed);

						// Figure out where to move this after the appropriate async transitions.
						resetStuff();
					};
				} catch (err) {
					// TODO If I couldn't get the codec I wanted, try again without specifying the codec.
					console.error("Error accessing the microphone, trying again without specifying codec");
				}
			};

			const p = createMediaRecorder();
			p.then(() => {
				mediaRecorder.current.start();
			});
		}
		function handleDiscardRecording() {
			// Do whatever special cleanup I need to do that may be distinct from the "paused" state.
			setMode("waiting");
			clearAudio();
		}


		// If we get here, it means the mode has changed, so handle the new mode.
		switch (mode) {
			case "startup":
				getSchedule();
				break;
			case "waiting":
				if (oldMode.current === "saving") {
					// setPromptIdx(p => {
					// 	let newPromptIdx = p + 1;
					// 	if (newPromptIdx >= prompts.length) {
					// 		newPromptIdx = 0;
					// 	}
					// 	return newPromptIdx;
					// });
				}
				break;
			case "recording":
				if (oldMode.current === "paused") {
					//console.log("resuming the recording");
					mediaRecorder.current.resume();
					resumeTimer();
				} else if (oldMode.current === "waiting") {
					handleStartRecording();
				}
				break;
			case "paused":
				//console.log("pausing the recording");
				mediaRecorder.current.pause();
				clearTimer();
				break;
			case "saving":
				handleSaveRecording();
				break;
			case "discard":
				handleDiscardRecording();
				break;
			default:
				// should never get here
				console.log("should never fall through the states!");
				// assert(false);
				break;
		}

		// Update the old mode to the current mode now that it's been handled.
		oldMode.current = mode;
	}, [mode, elapsed, promptTxt]);

	const postResponseData = async (promptID, promptTxt, elapsed) => {
		async function getBase64(blob) {
			const arrayBuffer = await blob.arrayBuffer();
			const uint8Array = new Uint8Array(arrayBuffer);

			// Note: it's possible to use TestDecoder to decode the uint8Array, but there's some weirdness with
			// it that I can't figure out atm, so just doing it the old-fashioned way.
			let parts = [];
			for (let i = 0; i < uint8Array.length; i++) {
				parts.push(String.fromCharCode(uint8Array[i]));
			}
			let binaryString = parts.join('');
			return btoa(binaryString);
		}

		// Send the collected stuff to local server, which will in turn send to BigQuery and other store.
		//const serverUrl = "http://localhost:8042/response_data";

		// Grab the endpoint from the environment variable.
		const serverUrl = `${process.env.REACT_APP_FLASK_SERVER}/response_data`;
		console.log("Checking env variable thing: " + serverUrl);

		let data = {}
		data['user_id'] = userID;
		data['id'] = promptID
		data['prompt_text'] = promptTxt;
		data['user_timezone'] = timeZoneIANA.current;
		data['audio_duration'] = elapsed;
		data['response_utc'] = new Date().toISOString().slice(0, 19);

		// Send the mimetype to the server, let it figure out how to name the file appropriately.
		data['container_and_encoding'] = mediaRecorder.current.mimeType;

		// Convert the audio blob to base64, so we can send it to the server.
		data['response_audio'] = await getBase64(audioBlob.current);

		try {
			const response = await fetch(serverUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			});

			if (!response.ok) {
				// Not sure what cleanup to do here.  At a minimum I guess I should reset the state?
				// Maybe have a state for error reporting that alerts the user that something went wrong
				// and that they should try again?  And that attempts to diagnose what happened?
				handleError(`HTTP error! status: ${response.status}`);
			} else {
				const result = await response.json();
				console.log("from server =>");
				console.log(result);

				// TODO transitioning to the proper state, esp in the case of errors, needs to be more robust.
				setMode("waiting");

				// TODO: I think this should be a call to resetStuff(), but I'm not sure.
			}
		} catch (error) {
			handleError('Error submitting data:', error);
		}
	}

	function getSchedule() {
		console.log("User in IANA tz " + timeZoneIANA.current);
		console.log("User in local tz " + timeZoneLocal.current);

		async function pleaseGetSchedule() {
			// Make a GET request to the server for this user's schedule.
			//const serverUrl = "http://localhost:8042/get_schedule";
			const serverUrl = `${process.env.REACT_APP_FLASK_SERVER}/get_schedule`;

			// The only data is the user_id and the timezone.  Optionally can include a particular date
			// to get the schedule for, but for now just get the current date.
			let now_obj = toLocalDateTimeObject(new Date());
			const data = {
				user_id: 1,
				user_timezone: timeZoneIANA.current,
				user_date: `${now_obj.date}`,
				user_time:	`${now_obj.time}`
			};

			let response = {}
			try {
				response = await fetch(serverUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),
				});

				if (!response.ok) {
					handleError(`HTTP error! status: ${response.status}`);
				} else {
					response = await response.json();
					// FIXME setMode("waiting");
					// I don't think I need these explicit waiting transitions.
				}
			} catch (error) {
				handleError('Error getting schedule:', error);
			}
			return response;
		}

		const p = pleaseGetSchedule();
		p.then((res) => {
			console.log("Here's the schedule:");
			console.log(res);
			scheduleEvents(res["schedule"]);
			// setMode("waiting");
		});
	}

	// NOTE I think I can have a leaner updater for prompt/prompt ID that's its own hook, vs the giant one below.
	// Maybe could move the success callback on scheduleEvents into its own hook, too.
	function scheduleEvents(schedule_str) {
		// schedule is a json string; convert it into a javascript object.
		let schedule = JSON.parse(schedule_str);

		schedule.forEach(({id, next_presentation, prompt_text}) => {
			const now = new Date();

			// next_presentation has dates in the format: Thu, 18 Jan 2024 22:09:00 GMT
			// This is misleading -- the actual date/time are in the user's local time, not GMT.
			// Convert this fake-GMT date to a real local date by chopping off the GMT part and adding the local timezone.
			let next_presentation_time = next_presentation.slice(0, -4);
			// console.log("next_presentation_time => " + next_presentation_time);

			// Something about this works on Chrome but not on Safari.  This is the console.log output:
			// [Log] next => Invalid Date
			// const next_old = new Date(next_presentation_time + " " + timeZoneLocal.current);

			// Create a date object from the next_presentation_time string
			let date = new Date(next_presentation_time);

			// Use the Intl.DateTimeFormat object to format the date string
			let formatter = new Intl.DateTimeFormat('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: false
			});

			// Format the date string
			let formattedDate = formatter.format(date);

			// Append the local timezone to the formatted date string
			const next = new Date(formattedDate + " " + timeZoneLocal.current);
			// console.log("next => " + next);

			// Print the next presentation time in local time.
			// console.log(`next presentation is at ${next}`);
			const delay = next - now;

			// Compute the delay in hours and minutes for clarity.
			const delay_hours = Math.floor(delay / (1000 * 60 * 60));
			const delay_minutes = Math.floor((delay / (1000 * 60)) % 60);
			// console.log(`scheduling ${id} at ${next_presentation}	-- in ${delay_hours} hours and ${delay_minutes} minutes (${delay} ms)`);

			setTimeout(() => {
				console.log(`firing ${id} at ${next_presentation} => ${prompt_text}`);

				// If the mode is in "waiting", then we can set the prompt text and prompt ID.
				// Otherwise, ignore it -- the user is in the middle of something.
				// (Later, I could queue up the prompt text and prompt ID, but for now, just ignore it.)
				//console.log(`mode is ${modeRef.current} and id is ${id}`);
				if (modeRef.current === "waiting" || modeRef.current === "startup") {
					setPromptTxt(prompt_text);
					setPromptID(id);
					setMode("waiting");
				} else {
					//console.log("mode is not waiting or startup, so not setting prompt text and prompt ID.");
					;
				}
			}, delay);
		})
	}

	function resumeTimer() {
		interval.current = setInterval(() => {
			setElapsed(prevDuration => {
				// let newDuration = prevDuration + 1;
				// Note that the log statement has been pulled into the functional update.
				// let rando = Math.random();
				// console.log("timer [" + rando + "]: setting next interval, [" + prevDuration + " => " + newDuration + "]");
				return prevDuration + 1;
			});
		}, 1000);
	}

	function clearTimer() {
		if (interval !== null) {
			clearInterval(interval.current);
			interval.current = null;
		}
	}

	function handleSaveRecording() {
		// This will trigger the onstop event, which will upload the audio for transcription.
		mediaRecorder.current.stop();
	}

	function clearAudio() {
		audioChunks.current = [];
		mediaRecorder.current = null;
		setElapsed(0);
	}

	function resetStuff() {
		clearAudio();
		setPromptTxt("<nothing scheduled yet>");
	}


	// Secondary hook for non state-machine stuff.  Should I move more stuff from the other one into here?
	// That might have been a good way to un-confuse things.
	useEffect(() => {
		// console.log("useEffect: elapsed is: " + elapsed);
		durationRef.current = elapsed;
	}, [elapsed]);

	const renderPrompt = () => {
		return (
				<div className="parent">
					<h2>Prompt</h2>
					<div className="parent Prompt">
						{promptTxt}
					</div>
				</div>
		);
	}

	function renderButtons() {
		return (
				<div className="fixed-height">
					{mode === "waiting" &&
							(<div>
									<button onClick={() => setMode("recording")}>Start Recording</button>
							</div>)}

					{mode === "recording" &&
							(<div>
									<button onClick={() => setMode("paused")}>Pause Recording</button>
							</div>)}

					{mode === "paused" &&
							(<div>
								<button onClick={() => setMode("recording")}>Resume Recording</button>
								<p></p>
								<button onClick={() => setMode("saving")}>Save clip</button>
								<p></p>
								<button onClick={() => setMode("discard")}>Discard clip</button>
							</div>)}
				</div>
		);
	}
	function renderTimer() {
		return (
				<div className="fixed-height">
					<h3>{elapsed} secs</h3>
				</div>
		);
	}

	return (
		<div className="container">
			<h1>VMR</h1>
			{renderPrompt()}
			{renderTimer()}
			{renderButtons()}
		</div>
	);
}

